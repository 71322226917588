<template style="z-index:1000">
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"/>
    <template >
      <h2><center>HomePage Carousel</center></h2>
    <br/><br/>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Carousels
        </h5>
  
        
        </div>
        <validation-observer ref="registerForm">
          <b-form @reset="onReset" class="auth-register-form mt-2 ml-2 mr-2">
            <b-form-group label="Title" label-for="title">
                <b-form-input id="title" v-model="title" name="title"
                   />
            </b-form-group>
            <b-form-group label="Description" label-for="Description">
                <b-form-input id="Description" v-model="description" name="Description"
               />
            </b-form-group>
            <b-form-group label="Image" label-for="image">
                <b-form-file v-on:change="retakePhoto" v-model="image" 
                />
            </b-form-group>
            
  
  
            <b-button variant="primary" size="lg" type="submit" @click.prevent="addCarousels">
              Save
            </b-button>
					<br/><small class="text-danger">{{ errors }}</small>
          </b-form>
          <b-modal ok-only v-model="success">
					Your Homepage Carousel Data is uploaded!
				</b-modal>
        </validation-observer>
  
  
    </template>
    <carouselList/>
  </div>
  </template>
  <script>
  
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BSidebar, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import { mapMutations, mapActions, mapState } from "vuex";
  import Password from "vue-password-strength-meter";
  import { uploadFile } from "../../../s3upload/index";
  // import fs from "fs";
  // import { parse } from "csv-parse";
  import carouselList from './homepageCarouselList.vue'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormFile,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      carouselList,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      VuePhoneNumberInput,
      Password
    },
    model: {
  
    },
    props: {
      
  
    },
    data() {
      return {
        imageURL:'',
        title:'',
        description:'',
        image: ''
      }
    },
    methods :{
      ...mapActions({ addCarousel: "adminCarousel/addCarousel", getS3Cred: "uploadToS3/getS3Cred", }),
      async onReset(){
        //resetform
        this.imageURL = '',
        this.title = '',
        this.description = ''
        this.image = ''
      },
      async retakePhoto(event) {
        console.log("%%%");
        console.log(event.target.name);
        console.log(event.target.files);
        const selectedFile = event.target.files;
        const fileName = selectedFile[0].name;
        await this.getS3Cred(fileName);
        const result = this.getS3CredFromStore;
        console.log("Result in m2 : ", result);
          this.imageURL = result.accessUrl
        await uploadFile(
          result,
          function () {},
          fileName,
          "uploads",
          selectedFile[0].type,
          selectedFile[0]
        );
      },
      async addCarousels() {
   
       
      await this.addCarousel({title: this.title, description: this.description, imageURL: this.imageURL})
      if (this.success == true){}
      this.onReset()
      },
      
    },
    computed: {
      ...mapState({
        
        getS3CredFromStore: (state) => state.uploadToS3.S3Cred,
			errors: (state) => state.adminCarousel.errors,
			success: (state) => state.adminCarousel.success

		
      }),
    },
  }
  
  </script>
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  